import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BackButton from "../BackButton";
import styled from "styled-components";
import CodeIcon from "../../images/codeIcon";
import EyeIcon from "../../images/eyeIcon";

const Introduction = ({ backTo, data }) => {
	return (
		<StyledIntroduction>
			<BackButton to={backTo} />
			<h1>{data.markdownRemark.frontmatter.title}</h1>
			<div className="project-links">
				{data.markdownRemark.frontmatter.code && (
					<a
						href={data.markdownRemark.frontmatter.code}
						target="_blank"
						rel="noreferrer"
					>
						<CodeIcon /> View Code
					</a>
				)}
				{data.markdownRemark.frontmatter.url && (
					<a
						href={data.markdownRemark.frontmatter.url}
						target="_blank"
						rel="noreferrer"
						id="view-project-link"
					>
						<EyeIcon /> View Project
					</a>
				)}
			</div>
			<p>{data.markdownRemark.frontmatter.desc}</p>
			<div className="project-main-image">
				<GatsbyImage
					image={getImage(data.markdownRemark.frontmatter.image)}
					alt={data.markdownRemark.frontmatter.title}
				/>
			</div>
		</StyledIntroduction>
	);
};

const StyledIntroduction = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: auto;
	h1 {
		font-size: 2rem;
		position: relative;
		margin-bottom: 2rem;
		&::before {
			content: "";
			height: 3px;
			width: 15%;
			background: var(--accent);
			position: absolute;
			bottom: -10px;
			border-radius: 5px;
		}
	}
	.project-links {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.8rem;
		a {
			text-decoration: none;
			font-weight: 500;
			font-size: 1.2rem;
			color: var(--gray);
			position: relative;
			display: flex;
			gap: 0.5rem;
			svg {
				color: var(--accent);
				width: 1.5rem;
			}
			&::before {
				content: "";
				width: 0;
				height: 3px;
				right: 0;
				bottom: -5px;
				background: var(--accent);
				position: absolute;
				border-radius: 5px;
				transition: 300ms;
			}
			&:hover::before {
				left: 0;
				width: 100%;
			}
		}
		#view-project-link {
			margin-left: auto;
		}
	}
	p {
		font-size: 1.2rem;
		margin-bottom: 0.8rem;
	}
	.project-main-image {
		border-radius: 10px;
		margin-bottom: 2rem;
		overflow: hidden;
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

export default Introduction;
