import React from "react";

const Ellipse = ({ className }) => (
	<svg
		width="323"
		height="323"
		viewBox="0 0 323 323"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<circle cx="161.5" cy="161.5" r="161.5" fill="url(#paint0_linear)" />
		<defs>
			<linearGradient
				id="paint0_linear"
				x1="323"
				y1="323"
				x2="0"
				y2="0"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F3F5F9" />
				<stop offset="1" stopColor="#EEE0FF" />
			</linearGradient>
		</defs>
	</svg>
);

export default Ellipse;
