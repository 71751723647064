import React from "react";
import ArrowLeft from "../images/arrowLeft";
import { Link } from "gatsby";
import styled from "styled-components";

const BackButton = ({ to }) => {
	return (
		<StyledBackButton to={to}>
			<ArrowLeft /> Go back
		</StyledBackButton>
	);
};

const StyledBackButton = styled(Link)`
	font-weight: 500;
	font-size: 1.2rem;
	color: var(--gray);
	position: relative;
	opacity: 0.6;
	margin-bottom: 0.5rem;
	display: inline-flex;
	align-items: center;
	column-gap: 0.4rem;
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	&:hover {
		opacity: 1;
	}
	svg {
		width: 1.2rem;
	}
`;

export default BackButton;
