import React from "react";
import styled from "styled-components";

const ProjectArticle = ({ data }) => {
	return (
		<StyledProjectArticle
			dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
		></StyledProjectArticle>
	);
};

const StyledProjectArticle = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: auto;
	margin-bottom: 2rem;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1rem;
		position: relative;
		::after {
			content: "";
			position: absolute;
			top: 0;
			left: -2.5%;
			height: 100%;
			width: 3px;
			background: var(--accent);
		}
	}
	p,
	li {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 400px) {
		width: 80%;
	}
`;

export default ProjectArticle;
