import React from "react";
import { withTheme } from "styled-components";

const DetailsCurveLine = (props) => {
	return (
		<svg
			width="884"
			height="902"
			viewBox="0 0 884 902"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M878.591 886.86C757.381 845.673 472.873 797.546 409.126 540.877C349.754 301.823 266.542 197.288 8.99987 12.3794"
				stroke="url(#paint0_linear)"
				strokeWidth="30"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="662.462"
					y1="976.549"
					x2="225.129"
					y2="-77.3104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--bg)" />
					<stop offset="1" stopColor={props.color} />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default withTheme(DetailsCurveLine);
