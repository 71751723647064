import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Introduction from "../components/projects/Introduction";
import ProjectDetails from "../components/projects/ProjectDetails";
import ProjectArticle from "../components/projects/ProjectArticle";
import { Helmet } from "react-helmet";

const ProjectPage = ({ location, data }) => {
	const backToLink =
		"state" in location
			? location.state !== null
				? "from" in location.state
					? location.state.from !== null
						? location.state.from
						: "/projects"
					: "/projects"
				: "/projects"
			: "/projects";
	return (
		<Layout pageTitle={`${data.markdownRemark.frontmatter.title} Project`}>
			<Helmet>
				<meta property="og:type" content="article" />
				<meta
					property="og:title"
					content={data.markdownRemark.frontmatter.title}
				/>
				{data.markdownRemark.frontmatter.image.publicURL && (
					<meta
						property="og:image"
						content={`${data.site.siteMetadata.siteUrl}${data.markdownRemark.frontmatter.image.publicURL}`}
					/>
				)}
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:title"
					content={data.markdownRemark.frontmatter.title}
				/>
				{data.markdownRemark.frontmatter.image.publicURL && (
					<meta
						name="twitter:image"
						content={`${data.site.siteMetadata.siteUrl}${data.markdownRemark.frontmatter.image.publicURL}`}
					/>
				)}
			</Helmet>
			<Introduction backTo={backToLink} data={data} />
			{(data.markdownRemark.frontmatter.features ||
				data.markdownRemark.frontmatter.stack) && (
				<ProjectDetails data={data} />
			)}
			{data.markdownRemark.html && <ProjectArticle data={data} />}
		</Layout>
	);
};

export const query = graphql`
	query ($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			frontmatter {
				desc
				title
				url
				code
				image {
					childImageSharp {
						gatsbyImageData(placeholder: DOMINANT_COLOR)
					}
					publicURL
				}
				features
				stack
				color
			}
			html
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default ProjectPage;
