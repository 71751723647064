import React from "react";
import styled from "styled-components";
import DetailsCurveLine from "../../images/detailsCurveLine";
import Ellipse from "../../images/ellipse";

const ProjectDetails = ({ data }) => {
	return (
		<StyledProjectDetails>
			{data.markdownRemark.frontmatter.color && (
				<>
					<div className="curve-lines">
						<DetailsCurveLine color={data.markdownRemark.frontmatter.color} />
						<DetailsCurveLine color={data.markdownRemark.frontmatter.color} />
						<DetailsCurveLine color={data.markdownRemark.frontmatter.color} />
					</div>
					<Ellipse className="ellipse" />
				</>
			)}
			<div id="content">
				{data.markdownRemark.frontmatter.features && (
					<div>
						<h2>Features</h2>
						<ul>
							{data.markdownRemark.frontmatter.features.map((feature) => (
								<li key={feature}>{feature}</li>
							))}
						</ul>
					</div>
				)}
				{data.markdownRemark.frontmatter.stack && (
					<div>
						<h2>Stack</h2>
						<ul>
							{data.markdownRemark.frontmatter.stack.map((technology) => (
								<li key={technology}>{technology}</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</StyledProjectDetails>
	);
};

const StyledProjectDetails = styled.div`
	width: 100%;
	min-height: 350px;
	border-top: 3px solid var(--accent);
	border-bottom: 3px solid var(--accent);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	margin-bottom: 2rem;
	.curve-lines {
		z-index: -10;
		svg {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: -10;
			&:nth-child(1) {
				top: -15px;
				left: -40%;
			}
			&:nth-child(2) {
				top: -55px;
				left: -37%;
			}
			&:nth-child(3) {
				top: -95px;
				left: -34%;
			}
		}
		@media (max-width: 1100px) {
			opacity: 0;
		}
	}
	.ellipse {
		position: absolute;
		top: -95px;
		right: -5%;
		z-index: -10;
		@media (max-width: 1500px) {
			right: -10%;
		}
		@media (max-width: 1100px) {
			opacity: 0;
		}
	}
	#content {
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		column-gap: 4rem;
		div {
			h2 {
				font-weight: 500;
				font-size: 1.3rem;
				text-align: center;
				margin: 2.8rem 0 1.8rem 0;
			}
			ul {
				font-size: 1.2rem;
				margin-bottom: 2.8rem;
				li {
					margin-bottom: 1.4rem;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		@media (max-width: 1300px) {
			width: 70%;
		}
		@media (max-width: 500px) {
			width: 90%;
			div {
				ul {
					margin-left: 1rem;
				}
			}
		}
		@media (max-width: 1100px) {
			flex-direction: column;
			div {
				ul {
					margin-bottom: 0;
				}
				&:last-child {
					ul {
						margin-bottom: 2.8rem;
					}
				}
			}
		}
	}
`;

export default ProjectDetails;
